'use strict';
var $ = window.$;

$(document).ready(function () {

  $.fancybox.defaults.touch = false;
  // $.fancybox.defaults.toolbar = false;
  $.fancybox.defaults.infobar = false;
  // $.fancybox.defaults.arrows = false;
  // $.fancybox.defaults.autoFocus = false;

   // Слайдеры
  $('[game-slider]').each(function(){
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
		let $dots = $block.find('.slider-dots');

    $init.on("beforeChange", function(event, slick, currentSlide, nextSlide){
      const nextActiveSlide = $init.find('[data-slick-index="' + nextSlide + '"]');
      nextActiveSlide.addClass('is-active');
      console.log(nextActiveSlide);
    })

    $init.on("init afterChange", function(event, slick, currentSlide){
      const activeSLide = $init.find('.slick-active');
      activeSLide.siblings().removeClass('is-active');
    }).slick({
			arrows: false,
      dots: true,
      variableWidth: true,
      appendDots: $dots,
		})    

    $(this).on('click', '.game-cards__item', function(){
      const index = $(this).attr('data-slick-index')
      $init.slick('slickGoTo', index)
    })
  })

  $('[actions-slider]').each(function(){
		let $block = $(this);
		let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');
    
    $init.slick({
			arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: false,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
      ] 
		})
  })

  // Открытие мобильного меню
  $('.js-toggle-menu').on('click', function(e){
		$('#mobile-menu').toggleClass('is-open');
		$('#hamburger').toggleClass('is-open');
  });
  
  $('.js-close-menu').on('click', function(e){
		$('#mobile-menu').removeClass('is-open');
		$('#hamburger').removeClass('is-open');
  });

  // плавная прокрутка
  $('a[href*="#"]').on("click", function (event) {
    event.preventDefault();
    var id = $(this).attr('href');
		var top = $(id).offset().top;
		$('#mobile-menu').removeClass('is-open');
    $('body,html').animate({
      scrollTop: top
    }, 500);
	});
});